import React from 'react'
import { navigate } from 'gatsby'
import LoginLayout from '../layouts/LoginLayout'

const NotFoundPage = () => {
  return(
  <LoginLayout>
    <div>
      <h1>Página no entontrada</h1>
      <p>Esta página no existe o no se encuentra disponible.</p>
      <p><button className="btn btn-default btn-block" onClick={() => navigate(-1)}>volver</button></p>
    </div>
  </LoginLayout>
)}

export default NotFoundPage
